import Vue from 'vue'
import VueRouter from 'vue-router'
// import HomeView from '../views/HomeView.vue'
import PCHome from '@/views/pc/home.vue'
import MHome from '@/views/m/home.vue'

import { IsPC as IsPC } from '@/utils/util'

Vue.use(VueRouter)

// if(window.location.host == 'zzcl.kjcxchina.com'){
//   indexUrl = '../views/pc/index.vue'
// }
// if(window.location.host=='jt.zzcl.kjcxchina.com'){
//   indexUrl = '../views/pc/group_index.vue'
//   // @/views/pc/group_index.vue
// }

const routes_pc = [
  {
    path: '*',
    redirect: '/'
  },

  {
    path: '/',
    name: 'PCHome',
    component: PCHome,
    children: [
      //首页
      {
        path: '/',
        name: 'Index',
        component: () => import('@/views/pc/index.vue'),
        meta:{
          topState:true
        }
      },{
        path: '/appList',
        name: 'appList',
        component: () => import('@/views/pc/appList.vue'),
        meta:{
          topState:true
        }
      },{
        path: '/appDetail',
        name: 'appDetail',
        component: () => import('@/views/pc/appDetail.vue'),
        meta:{
          topState:true
        }
      },{
        path: '/articleList',
        name: 'articleList',
        component: () => import('@/views/pc/articleList.vue'),
        meta:{
          topState:true
        }
      },{
        path: '/articleDetail',
        name: 'articleDetail',
        component: () => import('@/views/pc/articleDetail.vue'),
        meta:{
          topState:true
        }
      },{
        path: '/search',
        name: 'search',
        component: () => import('@/views/pc/search.vue'),
        meta:{
          headerIsShow:true
        }
      },{
        path: '/formIndex',
        name: 'formIndex',
        component: () => import('@/views/pc/formIndex.vue'),
        meta:{
          headerIsShow:true
        }
      },
    ]
  }
]
const routes_m = [
  {
    path: '*',
    redirect: '/'
  },
  {
    path: '/',
    name: 'mHome',
    component: MHome,
    children: [
      //首页
      {
        path: '/',
        name: 'Index',
        component: () => import('@/views/m/index.vue'),
        meta:{
        }
        
      },{
        path: '/appList',
        name: 'appList',
        component: () => import('@/views/m/appList.vue'),
        meta:{
          topState:true
        }
      },{
        path: '/appDetail',
        name: 'appDetail',
        component: () => import('@/views/m/appDetail.vue'),
        meta:{
          topState:true
        }
      },{
        path: '/articleList',
        name: 'articleList',
        component: () => import('@/views/m/articleList.vue'),
        meta:{
          topState:true
        }
      },{
        path: '/articleDetail',
        name: 'articleDetail',
        component: () => import('@/views/m/articleDetail.vue'),
        meta:{
          topState:true
        }
      },{
        path: '/search',
        name: 'search',
        component: () => import('@/views/m/search.vue'),
        meta:{
          headerIsShow:true
        }
      }
    ]
  },
]
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: IsPC() ? routes_pc : routes_m
  // routes: routes_pc 
})

export default router
