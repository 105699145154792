<template>
  <div class="m-home">
    <header-menu  v-if="headerState"  />
    <router-view />
    <!-- <foot-menu v-if="footerState"   /> -->
    <!-- <Tip :text="tipModal.text" :ico="tipModal.ico" :showBtns="tipModal.showBtns" v-if="tipModal.show" :fun="tipModal.fun" :confirmFun="tipModal.confirmFun" :confirmBtnText="tipModal.confirmBtnText" :cancelFun="tipModal.cancelFun" :cancelBtnText="tipModal.cancelBtnText" /> --> 
  </div>
</template>
<script>
import headerMenu from "@/components/m/header.vue";
// import Tip from "@/views/m/common/tip.vue";
// import headerMenu from "@/components/m/header.vue";
// import footMenu from "@/components/m/footer.vue";
import { mapState, mapMutations } from "vuex";
export default {
  name: "MHome",
  components: {
    headerMenu
    // Tip,
    //   headerMenu,
    //  footMenu,
  },
  computed: {
    ...mapState(["tipModal", "mLoginModal"]),
    // ...mapState(["tipModal", "loginModal"]),
  },
  watch: {
    $route: {
      handler(newVal) {
        this.head_title = newVal.meta.title
        const { headerIsShow, footerIsShow } = newVal.meta;
        this.headerState = headerIsShow ? false : true;
        this.footerState = footerIsShow ? false : true;
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
        
      },
      immediate: true,
    },
  },
  data() {
    return {
      head_title:'',
       headerState: true,
      footerState: true,
    };
  },
  mounted() {
    
    
  },
  methods: {
     ...mapMutations([
      "ShowMLoginModal",
      "CloseMLoginModal",
    ]), //登录弹窗

  },
};
</script>

<style lang="scss" scoped>
.scroll-box {
  height: 100vh;
  overflow-y: auto;
}
</style>
