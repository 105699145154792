<template>
  <!-- 头部信息 -->
  <div class="head_cont flex">
      <p class="logo" @click="$router.push('/')"><img src="@/assets/logo.png" alt="" /></p>
      <div class="right_info flex">
        <div class="goIndex">
          <p class="text"> <router-link to="/">首页</router-link> </p>
        </div>
        <template v-if="userInfo">
          <p class="img">
            <img :src="userInfo.avatarUrl" alt="" />
          </p>
          <!-- <div class="per_info">
            <p class="name">{{ userInfo.name }}</p>
          </div> -->
          <div class="get_out cursor" @click="getOut()">退出</div>
        </template>
        <!-- <div v-else class="get_out cursor" @click="loginBtn()">登录</div> -->
    </div>
    <Login v-if="mLoginModal.show" />
  </div>
</template>
<script>
import Login from "@/components/m/login.vue";
import { mapState, mapMutations } from "vuex";
export default {
  name: "header_box",
  data() {
    return {
      userInfo: "",
      loginState: false,
      codeValue: "", //code值
      hrefUrl: "", //当前网址链接
      token: "",
    };
  },
  components: {
    Login,
  },
  computed: {
    ...mapState(["tipModal", "mLoginModal"]),
  },
  mounted() {
    console.log('denglu')
    if (sessionStorage.getItem("userInfo")) {
      this.userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
    } else {
      let linkUrl = window.location.href;
      if (linkUrl.includes("?")) {
        //扫码成功后的链接
        this.codeValue = linkUrl.split("?")[1].split("=")[1].split("&")[0];
        this.hrefUrl = linkUrl.split("?")[0];
        this.getLoginToken();
      } else {
        // 未扫码的链接
        this.hrefUrl = linkUrl;
      }
    }
    if (!sessionStorage.getItem("userInfo") && window.h5sdk) {
      //start ljl
      window.h5sdk.ready(() => {
        // ready方法不需要每次都调用
        tt.requestAuthCode({
          appId: "cli_a4654bbbcffad013",
          success: (info) => {
            this.codeValue = info.code;
            this.hrefUrl = "https://ai.kjcxchina.com/";
            // this.hrefUrl = "http://localhost:8080/";
            this.getLoginToken();
          },
          fail: (error) => {
            console.error(error, "error");
            // alert(error);
          },
        });
      });
      // end ljl
    }

    // this.getLoginInfo();
  },
  methods: {
    ...mapMutations([
      "ShowMLoginModal",
      "CloseMLoginModal",
      "GetUserInfo",
      "ClearUserInfo",
      "ShowTipModal",
    ]), //登录弹窗
    // 获取登录token
    getLoginToken() {
      // this.userInfo='kkk'
      // sessionStorage.setItem('userInfo',JSON.stringify(this.userInfo))
      let formData = {
        code: this.codeValue,
        loginType: "web",
        redirectUri: encodeURIComponent(this.hrefUrl),
      };
      this.$axios.post(`/v1/member/login`, formData).then((res) => {
        if (res.code == 200) {
          this.token = res.token;
          sessionStorage.setItem("token", this.token);
          this.getLoginInfo();
        } else {
          // this.getCodeInfo()
        }
      });
    },
    getLoginInfo() {
      this.$axios.get(`/v1/member`, { useLog: true }).then((res) => {
        if (res.code == 200) {
          this.userInfo = res.data;
          sessionStorage.setItem("userInfo", JSON.stringify(this.userInfo));
          location.reload()
        }
      });
    },
    loginBtn() {
      this.ShowMLoginModal();
      // this.getCodeInfo();
    },
    closeLoginState() {
      this.CloseMLoginModal();
    },
    // 退出
    getOut() {
      sessionStorage.clear();
      location.reload();
    },
  },
};
</script>
<style scoped lang="scss">
.head_cont {
  width: 95%;
  justify-content: space-between;
  margin: 0px auto 0;
  overflow: hidden;
  height: 50px;
  position: absolute;
  left: 0;
  top: 0;
  p.logo{
    padding: 7px 0 0 12px;
    width: 112px;
    height: 23px;
    img{
      width: 100%;
      height: auto;
    }
  }
  .right_info {
    .goIndex{
      margin-right: 20px;
      a{
        color: #fff;
        font-size: 14px;
      }

    }
    p.img {
      width: 25px;
      height: 25px;
      img {
        width: 100%;
        height: 100%;
        border-radius: 100%;
      }
    }
    .per_info {
      border-right: 1px solid #d8d8d8;
      margin: 0 10px 0 10px;
      padding-right: 10px;
      p.name {
        font-size: 14px;
        font-family: Source Han Sans CN-Medium, Source Han Sans CN;
        font-weight: 500;
        color: #ffffff;
        line-height: 30px;
      }
      p.tips {
        font-size: 14px;
        font-family: Source Han Sans CN-Regular, Source Han Sans CN;
        font-weight: 400;
        color: rgba(255, 255, 255, 0.6);
        line-height: 18px;
      }
    }
    .get_out {
      font-size: 14px;
      font-family: Source Han Sans CN-Regular, Source Han Sans CN;
      font-weight: 400;
      color: #ffffff;
      margin-left: 5px;
    }
  }
}
</style>
