<template>
  <div class="home">
    <header-menu v-if="headerState" :logo="logoUrl" />
    <router-view />
     <Login v-if="loginModal.show" />
    <!-- <foot-menu  :style="topNum?'margin-top:'+'0px':'margin-top:120px'" v-if="footerState" :deepLogoUrl='deepLogoUrl' /> -->
  </div>
</template>
<script>
import headerMenu from "@/components/pc/header.vue";
// import footMenu from "@/components/pc/footer.vue";
import Login from "@/components/pc/login.vue";
import { mapState, mapMutations } from "vuex";
export default {
  name: "pc-home",
  components: {
    headerMenu,
    // footMenu,
    Login
  },
  computed: {
    ...mapState(["tipModal", "loginModal"]),
  },
  data() {
    return {
      headerState: true,
      footerState: true,
      logoUrl: '',
      deepLogoUrl:'',
      topNum:'',

      userInfo: "",
      loginState: false,
      codeValue: "", //code值
      hrefUrl: "", //当前网址链接
      token: "",
    };
  },
  watch: {
    $route: {
      handler(newVal) {
        console.log(newVal)
        const { headerIsShow, footerIsShow,topState } = newVal.meta;
        this.headerState = headerIsShow ? false : true;
        this.footerState = footerIsShow ? false : true;
        this.topNum =  topState ? true : false;
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
        
      },
      immediate: true,
    },
  },
  mounted() {
     if (sessionStorage.getItem("userInfo")) {
      this.userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
    } else {
      let linkUrl = window.location.href;
      if (linkUrl.includes("?")) {
        //扫码成功后的链接
        this.codeValue = linkUrl.split("?")[1].split("=")[1].split("&")[0];
        this.hrefUrl = linkUrl.split("?")[0];
         this.CloseLoginModal();
        this.getLoginToken();
      } else {
        // 未扫码的链接
        this.hrefUrl = linkUrl;
      }
    }
    if (!sessionStorage.getItem("userInfo") && window.h5sdk) {
      //start ljl
      window.h5sdk.ready(() => {
        // ready方法不需要每次都调用
        tt.requestAuthCode({
          appId: "cli_a4654bbbcffad013",
          success: (info) => {
            this.codeValue = info.code;
            this.hrefUrl = "https://zgkccase.kjcxchina.com/";
            this.getLoginToken();
          },
          fail: (error) => {
            console.error(error, "error");
            // alert(error);
          },
        });
      });
      // end ljl
    }
  },
  methods: {
    ...mapMutations([
      "ShowLoginModal",
      "CloseLoginModal",
      "GetUserInfo",
      "ClearUserInfo",
      "ShowTipModal",
    ]), //登录弹窗
     getLoginToken() {
      // this.userInfo='kkk'
      // sessionStorage.setItem('userInfo',JSON.stringify(this.userInfo))
      let formData = {
        code: this.codeValue,
        loginType: "web",
        redirectUri: encodeURIComponent(this.hrefUrl),
      };
      this.$axios.post(`/v1/member/login`, formData).then((res) => {
        if (res.code == 200) {
          this.token = res.token;
         
          sessionStorage.setItem("token", this.token);
          this.getLoginInfo();
        } else {
          // this.getCodeInfo()
        }
      });
    },
    getLoginInfo() {
      this.$axios.get(`/v1/member`, { useLog: true }).then((res) => {
        if (res.code == 200) {
          this.userInfo = res.data;
          this.CloseLoginModal();
          sessionStorage.setItem("userInfo", JSON.stringify(this.userInfo));
          location.reload()
        }
      });
    },
    loginBtn() {
      this.ShowLoginModal();
      // this.getCodeInfo();
    },
    closeLoginState() {
      this.CloseLoginModal();
    },
    // 退出
    getOut() {
      sessionStorage.clear();
      location.reload();
    },
  },
};
</script>

<style lang="scss">
*{
  // font-family: PingFangSC-Regular, sans-serif;
  font-family: PingFangTC-Regular, sans-serif;
}
.container_box{
  // padding-bottom: 150px;
}
 html,
      body {
        position: relative;
        // height: 100%;
      }
ul,li{
  list-style: none;
}
.flex {
  display: flex;
  align-items: center;
}
.cursor {
  cursor: pointer;
}
.none {
  display: none;
}
input::-webkit-input-placeholder {
  font-size: 14px;
}
input::-moz-input-placeholder {
  font-size: 14px;
}
input::-ms-input-placeholder {
  font-size: 14px;
}
.overflow_e {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  word-break: break-all; // 注意这个文字多行很重要
  -webkit-box-orient: vertical;
}
.abs_50{
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
}
.radius_5{
  border-radius: 5px;
}
</style>
