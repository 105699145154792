<template>
  <div>
    <div class="logo_cont_box">
        88888
      <!-- <div class="cont_data">
        <p class="close cursor" @click="closeLoginState()">
          <img src="@/assets/close.png" alt="" />
        </p>
        <p class="text">扫码登录</p>
        <div id="qCodeImg" class="qcode"></div>
        <p class="tips">请使用企业飞书扫码登录</p>
      </div> -->
    </div>
  </div>
</template>
<script>
import { mapState, mapMutations } from "vuex";
export default {
  data() {
    return {
      codeValue: "", //code值
      hrefUrl: "", //当前网址链接
      token: "",
    };
  },
  mounted() {
    console.log('ppp')
    if (!sessionStorage.getItem("userInfo") && window.h5sdk) {
      //start ljl
      window.h5sdk.ready(() => {
        // ready方法不需要每次都调用
        tt.requestAuthCode({
          appId: "cli_a4654bbbcffad013",
          success: (info) => {
            this.codeValue = info.code;
            this.hrefUrl = "https://zgkccase.kjcxchina.com/";
            // this.getLoginToken();
          },
          fail: (error) => {
            console.error(error, "error");
            // alert(error);
          },
        });
      });
      // end ljl
    }
  },
  methods: {
    ...mapMutations([
      "ShowMLoginModal",
      "CloseMLoginModal",
    ]), //登录弹窗
    // 获取二维码
    getCodeInfo() {
      setTimeout(() => {
        // 更换成自己的
        const CLIENT_ID = "cli_a4654bbbcffad013";
        // 更换成自己的
        // const REDIRECT_URI = encodeURIComponent(this.hrefUrl);
        const REDIRECT_URI = encodeURIComponent(
          "https://zgkccase.kjcxchina.com"
        );
        const goto = `https://www.feishu.cn/suite/passport/oauth/authorize?client_id=${CLIENT_ID}&redirect_uri=${REDIRECT_URI}&response_type=code&state=STATE`;
        const QRLoginObj = QRLogin({
          id: "qCodeImg",
          goto,
          width: "400",
          height: "300",
        });
        const handleMessage = function (event) {
          const origin = event.origin;
          // 使用 QRLoginObj.matchOrigin 方法来判断 message 是否来自飞书页面
          if (QRLoginObj.matchOrigin(origin)) {
            const tmp_code = event.data;
            // 在授权页面地址上拼接上参数 tmp_code，并跳转
            // 这里的 goto 是指上面创建 QRLogin 实例时的 goto 参数
            window.location.href=`${goto}&tmp_code=${tmp_code}`;
          }
        };
        if (typeof window.addEventListener != "undefined") {
          window.addEventListener("message", handleMessage, false);
        } else if (typeof window.attachEvent != "undefined") {
          window.attachEvent("onmessage", handleMessage);
        }
      }, 16);
    },
    
    closeLoginState() {
      this.CloseMLoginModal();
      // this.$router.push('/')
    },
  },
};
</script>
<style scoped lang='scss'>
.logo_cont_box {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.6);
  .cont_data {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 400px;
    height: 400px;
    background: #fff;
    border-radius: 5px;
    p.close {
      display: flex;
      justify-content: flex-end;
      margin: 20px 20px 0 0;
      img {
        width: 30px;
      }
    }
    p.text {
      text-align: center;
      font-weight: bold;
      color: #333;
      font-size: 20px;
      margin-top: -20px;
    }
    p.tips{
      text-align: center;
      font-size: 14px;
      color: #666;
      margin-top: -15px;
    }
    ::v-deep iframe {
      border: none;
    }
  }
}
</style>
